define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.UNAUTHORIZED_DIALOG_BODY_SELECTOR = void 0;
    var UNAUTHORIZED_DIALOG_BODY_SELECTOR;
    (function (UNAUTHORIZED_DIALOG_BODY_SELECTOR) {
        UNAUTHORIZED_DIALOG_BODY_SELECTOR["GUEST"] = "guest";
        UNAUTHORIZED_DIALOG_BODY_SELECTOR["PROMO"] = "promo";
        UNAUTHORIZED_DIALOG_BODY_SELECTOR["COMPANY_PASS"] = "company-pass";
        UNAUTHORIZED_DIALOG_BODY_SELECTOR["DEFAULT"] = "default";
    })(UNAUTHORIZED_DIALOG_BODY_SELECTOR || (exports.UNAUTHORIZED_DIALOG_BODY_SELECTOR = UNAUTHORIZED_DIALOG_BODY_SELECTOR = {}));
    ;
});
